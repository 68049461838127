import React, { FC } from 'react';
import { parseBoolean } from 'utils/parseHelpers';
import BreadCrumbsItem from './BreadCrumbsItem';
import { IBreadCrumbs } from './models';
import './BreadCrumbs.scss';

const BreadCrumbs: FC<IBreadCrumbs> = ({ data, showBreadcrumbs }) => {
  return parseBoolean(showBreadcrumbs) ? (
    <ul className="gs-breadcrumbs container-fluid">
      {data.map((item, index) => (
        <BreadCrumbsItem key={item.name} {...item} isActive={index === data.length - 1} />
      ))}
    </ul>
  ) : null;
};

export default BreadCrumbs;
