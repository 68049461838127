import React, { FC } from 'react';
import { Link } from 'gatsby';
import classNames from 'classnames';

import { IBreadCrumbsItemProps } from './models';

const BreadCrumbsItem: FC<IBreadCrumbsItemProps> = ({ link, name, isActive }) => {
  const classes = classNames('gs-breadcrumbs-item', {
    'gs-breadcrumbs-item__active': isActive,
  });

  return (
    <li className={classes}>
      <Link to={link}>{name}</Link>
      {!isActive ? <span className="icon-chevron-right" /> : null}
    </li>
  );
};

export default BreadCrumbsItem;
