const desktopSize = 570;
const windowParams = (isMobile: boolean) =>
  isMobile
    ? `menubar=no,toolbar=no,status=no,width=${window.innerWidth},height=570,top=0,left=0`
    : `menubar=no,toolbar=no,status=no,width=${desktopSize},height=${desktopSize},top=${
        (window.innerHeight - desktopSize) / 2
      },left=${window.innerWidth / 2 - desktopSize / 2}`;

const postTitle = encodeURI('Gaviscon');

const shareLinks = {
  facebook: (url: string) => `https://www.facebook.com/sharer.php?u=${url}`,
  twitter: (url: string, socialPostTitle: string) =>
    `https://twitter.com/share?url=${url}&text=${socialPostTitle || postTitle}`,
};

export { windowParams, shareLinks };
